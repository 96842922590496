/*###########################################################################
// Menu hover
//#########################################################################*/

import { gsap } from "gsap"

const menuHover = {
  config: {
    menuItem: '.header__submenu__link'
  },

  elements: {
    menuItem: null
  },

  init() {
    const self = this

    self.elements.menuItems = document.querySelectorAll(self.config.menuItem)

    if (self.elements.menuItems !== null && window.innerWidth > 1024) {
      for (let i = 0; i < self.elements.menuItems.length; i++) {
        const menuItem = self.elements.menuItems[i]

        self.handleMenuItemHover(menuItem, false)
      }

      if (typeof htmx !== 'undefined') {
        htmx.on('htmx:afterSwap', function (event) {
          setTimeout(function () {
            self.elements.menuItems = document.querySelectorAll(self.config.menuItem)

            if (self.elements.menuItems !== null) {
              for (let i = 0; i < self.elements.menuItems.length; i++) {
                const menuItem = self.elements.menuItems[i]

                self.handleMenuItemHover(menuItem, true)
              }
            }
          }, 250)
        })
      }
    }
  },

  handleMenuItemHover(menuItem, htmxSwap) {
    const self = this

    const image = menuItem.querySelector('.header__submenu__link__arrow')
    let imageWidth = image.clientWidth
    let imageHeight = image.clientHeight

    image.addEventListener('load', function() {
      imageWidth = image.clientWidth
      imageHeight = image.clientHeight
    })

    // Initial check for hover (for when sprig updates)
    //
    if (htmxSwap === true) {
      gsap.to(image, { autoAlpha: 0 })

      const event = new Event('mousemove');
      window.dispatchEvent(event);
    }

    menuItem.addEventListener('mouseenter', function(e) {
      gsap.to(image, {
				x: (e.offsetX - imageWidth / 2),
				y: (e.offsetY - imageHeight / 2),
				duration: 0.1
			})

      gsap.to(image, { autoAlpha: 1, duration: 0.005 })
    })

    menuItem.addEventListener('mousemove', function(e) {
      gsap.to(image, {
				x: (e.offsetX - imageWidth / 2),
				y: (e.offsetY - imageHeight / 2),
				duration: 0.1
			})
    })

    menuItem.addEventListener('mouseleave', function(e) {
      gsap.set(image, { autoAlpha: 0 })
    })
  }
}

window.addEventListener('load', function () {
  menuHover.init()
})
